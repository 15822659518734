.slotContainer {
  height: 100%;
  width: 100%;
  padding: .5vh;
  margin: 1;
}

.selectedSlot {
  border-radius: 1vh;
  padding: 0;
}