html {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d4d4d4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d4d4d4;
}